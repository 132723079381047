<template>
  <div class="query-dashboard">
    <div class="heading" v-if="dashboard && (showTitle || showReportName)">
      <h2 v-if="showTitle">{{dashboard.name}} - {{client}}</h2>
      <h1 v-if="showReportName">{{ dashboard.boundary_name }}</h1>
    </div>

    <div class="reboundary-card mb-4" v-if="showMap">
      <DashboardMap
          v-if="dashboard"
          :geojsondata="clusterBoundaries"
          :dashboard="dashboard"
          :poidata="poiData"
          v-on:set_current_cluster_id="set_current_cluster_id"
      />
    </div>

<!--    <div v-if="dataset">-->
<!--      <h3>REPORT HIGHLIGHTS</h3>-->
<!--      <div class="results_parent">-->
<!--        <div class="reboundary-card">-->
<!--          <highlights-component :dataset="dataset" :current_cluster="current_cluster"></highlights-component>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->

    <div v-if="loading">
      <Loading :is_loading="loading" :message="'Loading your Reboundary...'" />
    </div>

    <div v-if="dashboard" class="results_parent">
<!--      <b-card v-if="dataset" class="centred">-->
<!--        <b-button-->
<!--          size="lg"-->

<!--          v-for="(cluster, cluster_id) in this.dataset['cluster_information']" :key="cluster_id"-->
<!--          @click="set_current_cluster_id(cluster_id);"-->
<!--          :class="`cluster_select_button is_selected_${current_cluster_id == cluster_id}`"-->
<!--          :style="`background-color: ${colours[cluster_id]}`"-->
<!--        >-->
<!--          {{ convert_cluster_id_to_label(cluster_id) }}-->
<!--        </b-button>-->
<!--      </b-card>-->

<!--      <Loading :is_loading="loading" :message="'Loading your dashboard...'" />-->
      <b-row v-if="dataset" class="card-container" align-h="around">
        <DashboardStatistic
          v-for="statistic in contents"
          v-bind:key="statistic['id']"
          :statistic="statistic"
          :dataset="dataset"
          :current_cluster="current_cluster"
          :current_cluster_id="current_cluster_id"
          :debug_mode="dashboard['schema']['debug_mode']"
        />
      </b-row>
      <b-alert variant="danger" show v-if="errorMessage">
        <h3>Error Loading Dashboard</h3>
        <p>An error occurred while loading your Dashboard. Please try again later.</p>

        <h5>Details:</h5>
        {{errorMessage}}</b-alert>
    </div>
    <div v-if="pageConfig.navigation" class="page-navigation">
      <div v-for="(button, idx) of pageConfig.navigation" v-bind:key="idx">
        <b-button v-if="button.hyperlink === 'back'"
            class="rebutton" @click="$router.back()">
          {{button.text}}</b-button>
        <b-button v-else
                  class="rebutton"
                  @click="$router.push(('/home/reports/' + $route.params.dashboard_name + '/' + button.hyperlink)
                  .replace('//', '/'))">
          {{button.text}}</b-button>
      </div>

    </div>
    <div v-if="is_admin">
      <AdminReportDebugger></AdminReportDebugger>
    </div>
  </div>
</template>

<script>
import DashboardMap from '@/components/DashboardMap.vue';
import DashboardStatistic from '@/components/DashboardStatistic.vue';
import Loading from '@/components/Loading.vue';
import colours from '@/translation/cluster_colour_codes.js';
import AdminReportDebugger from "../components/AdminReportDebugger";

export default {
  name: 'QueryDashboard',
  components: {
    AdminReportDebugger,
    DashboardMap,
    DashboardStatistic,
    Loading
  },
  data() {
    return {
      loading: true,
      dashboard: null,
      content: '',
      pageConfig: {},
      dataset: null,
      clusterBoundaries: [],
      schema: null,
      showMap: true,
      showReportName: true,
      showTitle: true,
      current_cluster_id: 0,
      dashboard_disabled: false,
      colours,
      // Dataset selection. See the computed value dataset_options
      datasets_selected: {},
      errorMessage: null,
      client: '',
      // Default Stats Config
      // TODO - Maybe move this somewhere else?
      contents: null,
      poiData: null
    };
  },
  computed: {
    current_cluster() {
      if (this.dataset) {
        return this.dataset['cluster_information'][this.current_cluster_id];
      } else {
        return null;
      }
    },
    cluster_ids() {
      if (this.dataset) {
        let values = [];
        for (let key in this.dataset['cluster_information']) {
          let label = key;
          if (!isNaN(key)) {
            // if its a number
            label = `Cluster ${parseInt(key) + 1}`; // our numbering starts at 0, which isn't user friendly
          }
          values.push({
            text: label,
            value: key
          });
        }
        return values;
      } else {
        return null;
      }
    },
    is_admin() {
      console.log('this.$store.state.auth.user: ', this.$store.state.auth.user)
      return true
      // return !!this.$store.state.auth.user.is_admin
    }
  },
  methods: {
    is_active_cluster(cluster_id){
      if (cluster_id === this.current_cluster_id){
        return "active_cluster_button"
      }else{
        return ""
      }
    },
    convert_cluster_id_to_label(key) {
      if (!isNaN(key)) {
        // if its a number
        return `Cluster ${parseInt(key) + 1}`; // our numbering starts at 0, which isn't user friendly
      } else {
        return key;
      }
    },
    async run_dashboard(query_data) {
      this.dataset = false; // Turn off previous dataset. This triggers 'loading'
      this.loading = true
      if (this.dashboard_disabled) {
        // Used for testing, as we don't want to be loading the dashboard constantly
        console.error(
          'Dashboard disabled because this.dashboard_disabled is true! (in DashboardSingle.vue)'
        );
        return;
      }
      try {
        if (this.$store.state.dashboard.data &&
            this.$store.state.dashboard.currentDataId === this.$route.params.dashboard_name) {
          console.log('Using Dashboard Data')
        } else {
          await this.$store.dispatch('dashboard/getDashboardData', {
            dashboardId: this.$route.params.dashboard_name,
            parameters: query_data
          })
        }
        this.dataset = this.$store.state.dashboard.data
        this.clusterBoundaries = this.$store.state.dashboard.data.cluster_boundaries

        // Check if we already have a cluster selected. If we don't default to Total.
        if (!this.$store.state.dashboard.selectedCluster) {
          this.set_current_cluster_id('total')
        } else {
          this.current_cluster_id = this.$store.state.dashboard.selectedCluster
        }

        console.log('Page ID: ', this.$route.params.page_id)
        if (this.$route.params.hasOwnProperty('page_id') &&
            this.dashboard.schema.pages.hasOwnProperty(this.$route.params.page_id)) {
          this.pageConfig = this.dashboard.schema.pages[this.$route.params.page_id]
        } else {
          this.pageConfig = this.dashboard.schema.pages.default
        }

        this.contents = this.pageConfig.content
        if (this.pageConfig.hasOwnProperty('options')) {
          if (this.pageConfig.options.hasOwnProperty('show-title')) {
            this.showTitle = this.pageConfig.options["show-title"]
          }
          if (this.pageConfig.options.hasOwnProperty('show-report-name')) {
            this.showReportName = this.pageConfig.options["show-report-name"]
          }
          if (this.pageConfig.options.hasOwnProperty('show-map')) {
            this.showMap = this.pageConfig.options["show-map"]
          }
        }

        this.poiData = this.dataset.pois
        this.loading = false
        console.log('Page Config! ', this.pageConfig)
      } catch (error) {
        this.loading = false
        console.log(error)
        this.errorMessage = error.toString()
      }
    },
    set_current_cluster_id(new_current_cluster_id) {
      console.log('Setting Current cluster: ', new_current_cluster_id)
      this.$store.commit('dashboard/selectCluster', new_current_cluster_id)
      this.current_cluster_id = new_current_cluster_id;
    }
  },
  async mounted() {
    // DashboardService.getDashboardSchema(this.$route.params.dashboard_name).then(
    //   response => {
    //     this.dashboard = response.data['dashboard'];
    //     this.run_dashboard({}); // Start with default parameters
    //   },
    //   error => {
    //     this.content =
    //       (error.response && error.response.data) ||
    //       error.message ||
    //       error.toString();
    //   }
    // );
    this.client = this.$store.state.auth.user.organisation
    // If we already have data loaded, and it matches the route, keep it.
    if (this.$store.state.dashboard.schema &&
        this.$store.state.dashboard.currentSchemaId === this.$route.params.dashboard_name) {
      console.log('Using Existing Schema Data')
    } else {
      await this.$store.dispatch('dashboard/getDashboardSchema', this.$route.params.dashboard_name)
    }
    console.log('Schema: ', this.$store.state.dashboard.schema)
    console.log('Data: ', this.$store.state.dashboard.data)
    this.dashboard = this.$store.state.dashboard.schema
    await this.run_dashboard({})
    console.log(this.current_cluster_id)
  },
  watch: {
    '$store.state.dashboard.selectedCluster': function (newCluster) {
      this.current_cluster_id = newCluster
    }
  }
};
</script>

<style scoped>

.query-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2em 4em;
}

.heading {
  /*padding-left: 5px;*/
}

.reboundary-card {
  /*margin: ;*/
}


.card-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.fullwidth {
  margin: 0;
  border: 0;
  width: 100%;
  background-color: #e3e3e4;
  background: linear-gradient(180deg, #e3e3e4 0%, #c7c7c7 100%);
}

.results_parent {
  /*width: 80%;*/
  /*min-width: 400pt;*/
  /*margin: auto;*/
  /*padding: 16pt;*/
}

.centred {
  margin: auto;
  text-align: center;
}

.cluster_select_button {
  text-transform: capitalize;
  margin: 4pt;
}

.is_selected_true{
  border: 3px solid rgb(82, 82, 82);
  box-shadow: 2px solid grey;
}

.page-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

</style>
