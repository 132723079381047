<template>
  <div class="reboundary-card admin-report-debugger" v-if="this.$store.state.dashboard.data">
    <h3>Admin Report Debugger</h3>
    <div class="debugger-item" ref="cluster-inspector">
      <h5 @click="clickToggleExpandClusterInspector">Data Inspector
        <font-awesome-icon icon="caret-left" v-if="!expandClusterInspector"></font-awesome-icon>
        <font-awesome-icon icon="caret-down" v-if="expandClusterInspector"></font-awesome-icon>
      </h5>
      <div v-if="expandClusterInspector" class="object-inspector">
        <div v-for="(item, pathIdx) of path" v-bind:key="pathIdx" class="path-item-container">
          <div class="path-item-inner">
            <h5 v-if="pathIdx > 0">{{formatObjectName(pathTitles[pathIdx])}}</h5>
            <h5 v-if="pathIdx === 0">Root</h5>
            <h6>Type: {{getObjectType(item)}}</h6>
            <div v-if="getObjectType(item) ==='Object'" >
              <select size="10" @change="clickObjectAttribute(item, pathIdx, $event)">
                <option v-for="(attr, idx) of Object.keys(item)" v-bind:key="idx" :value="attr">
                  {{formatObjectName(attr)}}
                </option>
              </select>
            </div>
            <div v-else-if="getObjectType(item) === 'Array'" class="path-item-container">
              <select size="10" @change="clickObjectAttribute(item, pathIdx, $event)">
                <option v-for="(attr, idx) of item" v-bind:key="idx" :value="idx">
                  Item {{idx}}
                </option>
              </select>
            </div>
            <div v-else class="path-item-container">
              <strong>Value:</strong>
              <input type="text" :value="item">
            </div>
          </div>
          <div class="path-item-suffix" v-if="pathIdx < (path.length - 1)">
            <font-awesome-icon icon="caret-right"></font-awesome-icon>
            <font-awesome-icon icon="caret-right"></font-awesome-icon>
            <font-awesome-icon icon="caret-right"></font-awesome-icon>
          </div>
        </div>


      </div>
    </div>

<!--    Raw JSON View-->
    <div class="debugger-item">
      <h5 @click="clickToggleExpandRawJSON">Raw JSON
        <font-awesome-icon icon="caret-left" v-if="!expandRawJSON"></font-awesome-icon>
        <font-awesome-icon icon="caret-down" v-if="expandRawJSON"></font-awesome-icon>
      </h5>
      <div v-if="expandRawJSON">
        <textarea type="text" rows="10" class="w-100" :value="rawJSONData" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminReportDebugger",
  data () {
    return {
      expandClusterInspector: false,
      expandRawJSON: false,
      path: [],
      pathTitles: ['Root']
    }
  },
  mounted () {
    if (this.$store.state.dashboard.data) {
      this.path = [this.$store.state.dashboard.data]
    }
  },
  methods: {
    clickToggleExpandClusterInspector () {
      this.expandClusterInspector = !this.expandClusterInspector
    },
    clickToggleExpandRawJSON () {
      this.expandRawJSON = !this.expandRawJSON
    },
    clickObjectAttribute (item, idx, event) {
      if (idx < (this.path.length - 1)) {
        this.path = this.path.slice(0, idx + 1)
        this.pathTitles = this.pathTitles.slice(0, idx + 1)
      }
      this.path.push(item[event.target.value])
      this.pathTitles.push(event.target.value)
      // When the view has finished updating, scroll to the right.
      this.$nextTick(() => {
        this.$refs["cluster-inspector"].scrollTo(
            {
              left: this.$refs["cluster-inspector"].scrollWidth,
              behavior: 'smooth'
            })
      })

    },
    getObjectType (item) {
      if (typeof item === 'object' && Array.isArray(item)) {
        return 'Array'
      } else if (typeof item === 'object' && !Array.isArray(item)) {
        return 'Object'
      } else {
        return typeof item
      }
    },
    formatObjectName (object) {
      let name = object.toString()
      if (name.length > 32) {
        return name.slice(0, Math.min(name.length, 31))  + '...'
      } else {
        return name
      }
    }
  },
  computed: {
    rawJSONData() {
      if (this.$store.state.dashboard.data) {
        return JSON.stringify(this.$store.state.dashboard.data.cluster_information, null, 2)
      } else {
        return ''
      }
    }
  },
  watch: {
    '$store.state.dashboard.data' () {
      this.path = [this.$store.state.dashboard.data]
      console.log('Data Changed:  ', this.$store.state.dashboard.data)
    }
  }
}
</script>

<style scoped>
.admin-report-debugger {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.debugger-item {
  width: 100%;
  overflow-x: auto;
}

.object-inspector {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.object-inspector > div  {
  margin-left: 1em;
}

.object-inspector > div:first-child  {
  margin-left: 0;
}

.path-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.path-item-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background: #8ed0ff;
  padding: 10px;
  border-radius: 25px;
}

.path-item-suffix {
  font-size: 3em;
  color: #1c51b9;
  display: flex;
  margin-left: 5px;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

</style>
